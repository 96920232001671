.container-foodex {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 3em;
  column-gap: 3em;
  padding: 5em;
  padding-left: 4em;
  padding-right: 4em;
  overflow: scroll;
  position: relative;
  top: 4em;
  max-height: 80vh;
  height: auto;
  padding-top: 0;
  margin-bottom: 1em;
  align-items: flex-start;
}

.ant-card-body {
  padding: 2em !important;
}

@media (max-width: 60rem) {
  .container-foodex {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1em;
    column-gap: 0.5em;
    padding: 0.25em;
    padding-bottom: 4em;
    align-items: flex-start;
  }

  .container-foodex > .grid > a {
    width: 100%;
  }

  .grid {
    width: 100%;
  }

  .ant-card {
    width: 100%;
  }

  .ant-card-body {
    padding: 0.5em !important;
  }

  .icons {
    display: flex;
  }

  .icons > svg {
    height: 3em !important;
    width: 3em !important;
  }

  .saison-icon > div {
    font-size: 1.5em !important;
  }
}
