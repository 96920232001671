.lists {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 2em;
  column-gap: 2em;
}

.body-container {
  display: flex;
  justify-content: space-between;
}

.body-container > p {
  margin: 0;
}

.body-container > button > span[aria-label="border"],
.body-container > button > span[aria-label="check-square"] {
  color: green;
}

.body-container > button > span[aria-label="border"]:hover,
.body-container > button > span[aria-label="check-square"]:hover {
  color: rgb(0, 102, 0);
}

.body-container > button > span[aria-label="delete"] {
  color: red;
}

.body-container > button > span[aria-label="delete"]:hover {
  color: rgb(199, 0, 0);
}

.shopping-list > .container {
  display: flex;
  flex-direction: column;
  height: 75vh;
}

.title-shopping-list {
  display: flex;
  width: 100%;
  justify-content: center;
}

.title-shopping-list > h1 {
  margin: 0;
}
