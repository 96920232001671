@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  color: var(--PRIMARY);
}

.app {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}

.app-body {
  position: absolute;
  top: 5em;
  width: 100%;
  padding: 1em;
  height: 77vh;
}

.grid {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.ant-card-cover {
  height: auto !important;
}

.container-details {
  /* height: 80vh; */
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container-details > img {
  height: auto;
  width: 100%;
  padding: 2em;
  max-width: 30vw;
}

.container-details > .info-details > .title-details > h1 {
  margin: 0;
  align-self: center;
}

.container-details > .info-details > .title-details > svg {
  font-size: 2em;
}

.container-details > .info-details > .title-details {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-self: flex-start;
}

.container-details > .info-details {
  display: grid;
  grid-template-rows: 1fr 6fr;
}

@media (max-width: 60rem) {
  .container-details {
    padding: 0;
    /* top: 4em; */
    display: flex;
    flex-direction: column;
  }

  .container-details > img {
    width: 100%;
    padding: 0;
    max-width: none;
  }

  .container-details > .info-details > .title-details > h1 {
    margin: 0;
    padding: 0;
  }
}
