*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-right: 2rem;
    padding-left: 2rem
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-right: 4rem;
    padding-left: 4rem
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-right: 5rem;
    padding-left: 5rem
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-right: 6rem;
    padding-left: 6rem
  }
}

.visible {
  visibility: visible
}

.m-0 {
  margin: 0px
}

.mt-20 {
  margin-top: 5rem
}

.block {
  display: block
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.h-screen {
  height: 100vh
}

.h-2 {
  height: 0.5rem
}

.h-auto {
  height: auto
}

.h-3 {
  height: 0.75rem
}

.h-20 {
  height: 5rem
}

.h-48 {
  height: 12rem
}

.h-40 {
  height: 10rem
}

.h-60 {
  height: 15rem
}

.h-10 {
  height: 2.5rem
}

.max-h-24 {
  max-height: 6rem
}

.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.w-screen {
  width: 100vw
}

.w-60 {
  width: 15rem
}

.w-full {
  width: 100%
}

.w-auto {
  width: auto
}

.w-3 {
  width: 0.75rem
}

.w-40 {
  width: 10rem
}

.w-80 {
  width: 20rem
}

.columns-5 {
  -webkit-columns: 5;
          columns: 5
}

.columns-3xs {
  -webkit-columns: 16rem;
          columns: 16rem
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.flex-col {
  flex-direction: column
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-items-center {
  justify-items: center
}

.gap-2 {
  gap: 0.5rem
}

.gap-3 {
  gap: 0.75rem
}

.gap-5 {
  gap: 1.25rem
}

.gap-x-9 {
  -webkit-column-gap: 2.25rem;
          column-gap: 2.25rem
}

.gap-y-5 {
  row-gap: 1.25rem
}

.justify-self-end {
  justify-self: end
}

.justify-self-center {
  justify-self: center
}

.rounded-lg {
  border-radius: 0.5rem
}

.border-0 {
  border-width: 0px
}

.bg-slate-200\/25 {
  background-color: rgb(226 232 240 / 0.25)
}

.bg-blue-600\/50 {
  background-color: rgb(37 99 235 / 0.5)
}

.object-contain {
  object-fit: contain
}

.object-cover {
  object-fit: cover
}

.object-none {
  object-fit: none
}

.object-scale-down {
  object-fit: scale-down
}

.p-4 {
  padding: 1rem
}

.p-0 {
  padding: 0px
}

.p-1 {
  padding: 0.25rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem
}

.pb-28 {
  padding-bottom: 7rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.font-semibold {
  font-weight: 600
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-slate-200\/25 {
  --tw-shadow-color: rgb(226 232 240 / 0.25);
  --tw-shadow: var(--tw-shadow-colored)
}

.shadow-slate-200\/50 {
  --tw-shadow-color: rgb(226 232 240 / 0.5);
  --tw-shadow: var(--tw-shadow-colored)
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity))
}

.hover\:bg-slate-100\/25:hover {
  background-color: rgb(241 245 249 / 0.25)
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

@media (min-width: 768px) {
  .md\:mt-10 {
    margin-top: 2.5rem
  }

  .md\:max-h-80 {
    max-height: 20rem
  }

  .md\:w-96 {
    width: 24rem
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .md\:gap-4 {
    gap: 1rem
  }

  .md\:gap-6 {
    gap: 1.5rem
  }

  .md\:gap-5 {
    gap: 1.25rem
  }
}
